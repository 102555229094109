import { Controller } from "@hotwired/stimulus";
import { createAlert } from "../packs/createAlert";
export default class extends Controller {
  static targets = [
    "modal",
    "promoteButton",
    "recipientCheckbox",
    "sendNotificationButton",
    "viewButton",
    "notificationRecipients",
  ];
  static values = {
    template: String,
  };

  connect() {
    console.log(
      "Hello, Stories Interview Template Tag Controller here!",
      this.element
    );
  }

  addTag(event) {
    event.preventDefault();
    Turbo.visit(`/interview_templates/${this.templateValue}/tags/new`, {
      frame: "new-tag-form",
    });
  }

  // saveNewTag(event) {
  //   event.target.closest(".row").remove();
  // }

  removeNewRow(event) {
    event.preventDefault();
    event.target.closest(".row").remove();
  }
}
