/*

 _____                  ____       _
|_   _|__  _   _ _ __  / ___|  ___| |_ _   _ _ __
  | |/ _ \| | | | '__| \___ \ / _ \ __| | | | '_ \
  | | (_) | |_| | |     ___) |  __/ |_| |_| | |_) |
  |_|\___/ \__,_|_|    |____/ \___|\__|\__,_| .__/
                                            |_|

1. Assign to data-controller='help-tour' in the element you want to trigger the tour
2. Also pass in the slug of the tour to display data-help-tour-slug-value='{slug}'
3. If you want to show the tour on page load, pass in data-help-tour-show-tour-value='true'
4. Make sure you have a div with the class 'entity-admin-content-page' to scroll to the top of the page when showing a step
5. The first step should start with a custom_element=true
6. Make sure you have data-tour-key on the element you want to trigger the tour step (usually a tab)
*/

import { Controller } from "@hotwired/stimulus"
import Shepherd from 'shepherd.js';
import imageUrl from 'images/logo-black-small.png';

export default class extends Controller {
  static targets = [ "tourButton" ]
  static values = {
    slug: String,
    showTour: Boolean,
    showTourKey: Number
  }

  tour = {};
  tourData = {};

  async connect() {
    // console.log("Got connected to Help Tour Controller");
    // console.log('Show Tour:', this.showTourValue);
    // console.log('Slug Value:', this.slugValue);
    // console.log('IMAGE URL ------------', imageUrl);

    const response = await fetch(`/help_tours/${this.slugValue}`)

    if (response.status !== 200) {
      console.info('No product tour data was returned.');

      // remove the tour button from the UI for this page if nothing is returned
      this.tourButtonTarget.style.display = 'none';

      return;
    }

    this.tourData = await response.json();

    // console.log('Tour Data: ', this.tourData);

    this.prepareTourSteps(this.tourData);

    // init localStorage if needed
    let localStorageTour = localStorage.getItem(`${this.slugValue}-tour`);
    if (!localStorageTour) {
      this.resetTour();
      this.showTourStep(0);
    }

    // Listen for tabs to change and retrigger tour logic
    var tabs = document.querySelectorAll('button[data-bs-toggle="tab"]')
    tabs.forEach(elm => {
      elm.addEventListener('shown.bs.tab', (event) => {
        if (!this.didAlreadyDismissedStep(event.target.dataset.tourKey)) {
          this.showTourStep(event)
        }
      });
    });

    if (this.showTourValue === true) {
      let tourKey;
      if (this.showTourKeyValue) {
        tourKey = this.showTourKeyValue;
      } else {
        const activeTab = document.querySelector('button.nav-link.active');
        tourKey = activeTab.dataset.tourKey;
      }

      if (!this.didAlreadyDismissedStep(tourKey)) {
        this.showTourStep(parseInt(tourKey))
      }
    }
  }

  async prepareTourSteps(data) {

    this.tour = new Shepherd.Tour({
      useModalOverlay: true,
      exitOnEsc: true,
      keyboardNavigation: true,
      defaultStepOptions: {
        classes: `fanword-shepherd-tour ${this.slugValue}-tour-step`,
        modalOverlayOpeningPadding: 10,
        scrollTo: false,
        popperOptions: {
          modifiers: [{ name: 'offset', options: { offset: [0, 20] } }]
        }
      }
    });

    // loop data.steps to create steps
    this.tourData.steps.sort((a,b) => a.order - b.order).forEach((step, index) => {

      this.tour.addStep({
        id: `${this.slugValue}-step-${index}`,
        text: this.addStepTextWrapper(step.custom_format, step.content),
        attachTo: step.element_selector ? {
          element: step.element_selector,
          on: step.placement
        } : null,
        popperOptions: {
          modifiers: [{ name: 'offset', options: { offset: [60, 20] } }]
        },
        buttons: this.addStepButtons(step.custom_format, step.auto_advance, index),
        when: this.addStepWhenShown(step.custom_format, step.auto_advance)
      })

    });

  }

  completeStep(key) {
    let storage = JSON.parse(localStorage.getItem(`${this.slugValue}-tour`));
    storage[key] = true;
    localStorage.setItem(`${this.slugValue}-tour`, JSON.stringify(storage))
  }

  didAlreadyDismissedStep(key) {
    let storage = JSON.parse(localStorage.getItem(`${this.slugValue}-tour`));
    return storage[parseInt(key)]
  }

  showTourStep(tourKeyOrEvent) {
    // ensure we're at the top of the page before showing step
    document.querySelector('.entity-admin-content-page').scroll({ top: 0 });

    let tourKey;
    if (typeof tourKeyOrEvent === 'object') {
      tourKey = tourKeyOrEvent.target.dataset.tourKey;
    } else {
      tourKey = tourKeyOrEvent
    }

    let storage = JSON.parse(localStorage.getItem(`${this.slugValue}-tour`));
    storage[tourKey] = false;
    localStorage.setItem(`${this.slugValue}-tour`, JSON.stringify(storage));

    console.log('Showing tour key', tourKey);

    this.tour.show(parseInt(tourKey));
  }

  resetTour(event) {
    let tourSteps = {};
    this.tourData.steps.forEach((step, index) => {
      tourSteps[index] = false;
    });

    localStorage.setItem(`${this.slugValue}-tour`, JSON.stringify(tourSteps));

    if (event && event.target) {
      this.tour.show(0);
    }
  }

  addStepTextWrapper(custom_format, content) {
    let text;

    // if we aren't attached to an element, it's the special first step
    if (custom_format) {

      text = `
        <div class='bg-fanword-blue d-flex flex-column justify-content-center align-items-center' style='min-height: 200px;'>
          <div class='bg-fanword-white my-5 d-flex flex-direction-column justify-content-center align-items-center' style='width: 100px; height: 100px; border-radius: 100px;'>
            <img src='${imageUrl}' style='width: 50%; display: block; margin: 0 auto;' />
          </div>
        </div>
        <div class= p-5'>
          <div class='ql-editor'>
            ${content}
          </div>
          <div class='text-center mb-5'>
            <button class='btn-sm border-0 bg-fanword-yellow px-5 py-3 mb-3 color-fanword-black' type='button' id='tour-continue'>
              Get Started
            </button>
            <br>
            <a class='color-fanword-text font-fanword-regular text-decoration-none' href='#' id='tour-skip'>No thanks, I'll explore on my own.</a>
          </div>
        </div>`

    } else {

      text = `<div class='p-4'>
        <p class='font-fanword-regular'>
          ${content}
        </p>
      </div>`

    }

    return text;
  }

  addStepButtons(custom_format, auto_advance, index) {

    if (custom_format) {

      return [];

    } else {

      const lastStep = this.tourData.steps.length === index + 1;

      return [{
        text: lastStep ? 'Finish' : 'Got it',
        action: () => {
          this.completeStep(index);
          this.tour.hide();
          if (auto_advance) {
            this.tour.next();
          }

          if (lastStep) {
            this.tourData.steps.forEach((step, index) => {
              this.completeStep(index);
            });

            this.tour.complete();
          }
        },
        classes: 'btn-sm bg-fanword-yellow border-0 ms-2 px-4 py-2 color-fanword-black text-decoration-none'
      }]

    }

  }

  addStepWhenShown(custom_format) {

    if (custom_format) {
      return {
        show: () => {
          const skipButton = document.querySelector('#tour-skip')
          skipButton.addEventListener('click', (event) => {
            event.preventDefault();
            this.tour.complete();

            this.tourData.steps.forEach((step, index) => {
              this.completeStep(index);
            });
          })

          const goButton = document.querySelector('#tour-continue')
          goButton.addEventListener('click', (event) => {
            this.completeStep(0);
            this.tour.next()
          })
        }
      }
    }

  }

}