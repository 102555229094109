// app/javascript/controllers/contrast_controller.js

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { bgColor: String };

  connect() {
    this.setContrastTextColor();
  }

  setContrastTextColor() {
    const bgColor = this.bgColorValue;

    if (!bgColor) {
      console.error("No background color provided!");
      return;
    }

    const contrastColor = this.calculateContrastColor(bgColor);
    this.element.style.backgroundColor = bgColor;
    this.element.style.color = contrastColor;
  }

  calculateContrastColor(bgColor) {
    let r, g, b;

    // Convert HEX to RGB
    if (bgColor.startsWith("#")) {
      const bigint = parseInt(bgColor.slice(1), 16);
      r = (bigint >> 16) & 255;
      g = (bigint >> 8) & 255;
      b = bigint & 255;
    }
    // Handle RGB colors
    else if (bgColor.startsWith("rgb")) {
      [r, g, b] = bgColor.match(/\d+/g).map(Number);
    } else {
      console.error("Invalid color format:", bgColor);
      return "#000"; // Default to black
    }

    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Return white for dark backgrounds, black for light backgrounds
    return luminance > 0.5 ? "#000" : "#fff";
  }
}