import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "content", "link" ]

  connect() {
    // console.log("Hello, Help Page Controller here!", this.element);
    // console.log(this.pageTargets);

    window.addEventListener('hashchange', function() {
      // Code to execute when the hash changes
      // console.log('Hash changed:', window.location.hash);
      this.extractPageId();
    });

    this.extractPageId();
  }

  extractPageId() {
    const params = new URLSearchParams(window.location.search);
    const help_page_id = params.get('help_page_id');

    if (help_page_id) {
      // open the parent page
      const target = this.linkTargets.find(link => link.dataset.helpPageIdParam == help_page_id)
      const accordionButton = target.closest('.accordion-collapse').parentElement.querySelector('.accordion-button');
      if (accordionButton) {
        console.log('Clicking on accordion!', accordionButton);
        accordionButton.click();
      }

      // load and display the correct page
      this.loadPage({
        target: target,
        params: { id: help_page_id }
      });
    }
  }

  loadPage(event) {
    // console.log('loadPage', event.params);
    const url = `/help_pages/${parseInt(event.params.id, 10)}`;
    this.displayHelpPage(url);

    this.linkTargets.forEach(link => link.classList.remove('active'));
    event.target.classList.add('active');
  }

  displayHelpPage(url) {
    // console.log('Attempting to load page', url);

    fetch(url).then(response => {
      if (response.ok) {
        return response.text();
      } else {
        throw new Error('Network response was not ok.');
      }
    }).then(html => {
      this.contentTarget.innerHTML = html;
    }).catch(error => {
      console.error('There has been a problem with your fetch operation:', error);
    })
  }

}
