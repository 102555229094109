import { Controller } from "@hotwired/stimulus"
import intializeBootstrapTooltips from '../scripts/tooltips';
import initializeClickTracking from '../scripts/click-tracking.js';
import initializeMaxCharacterTracking from '../scripts/character-tracking.js';

export default class extends Controller {

  connect() {
    $('.alert').not('.persistent').not('.alert-danger').delay(3000).fadeOut(3000);

    // TODO these can be replaced with stimulus controllers in the future
    intializeBootstrapTooltips();
    initializeClickTracking();
    initializeMaxCharacterTracking();

    console.clear();
  }

}

