const intializeBootstrapTooltips = () => {
  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    // console.log('Init tooltip')
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });
};

module.exports = intializeBootstrapTooltips;
