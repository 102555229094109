import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "recipient", "recipientCheckbox", "recipientsContainer", "sendNotificationButton", "notificationRecipients", "addRecipientSaveButton" ]
  static values = {
    templateId: String
  }

  connect() {
    console.log("Hello, Stories Interview Template Recipients Controller here!", this.element)
    // console.log(this.modalTarget);
    // console.log(this.templateValue);
  }

  addRecipient() {
    fetch(`/interview_templates/${this.templateIdValue}/recipients/new`).then(res => res.text()).then(partial => {
      // partial has 3 form fields, need to replace it globally
      partial = partial.replace(/NEW_ID/g, this.recipientTargets.length + 1)

      this.recipientsContainerTarget.insertAdjacentHTML( 'beforeend', partial );

      if (this.recipientTargets.length > 0) {
        // console.log('> 0')
        this.addRecipientSaveButtonTarget.classList.remove("d-none");
        this.addRecipientSaveButtonTarget.classList.add("d-block");
      } else {
        // console.log('else')
        this.addRecipientSaveButtonTarget.classList.remove("d-block");
        this.addRecipientSaveButtonTarget.classList.add("d-none");
      }
    });
  }

  toggleAllRecipients(event) {
    if (event.target.checked === false) {
      this.recipientCheckboxTargets.filter((checkbox) => !checkbox.disabled).forEach((checkbox) => {
        checkbox.checked = false;
      });
      this.notificationRecipientsTarget.value = JSON.stringify([]);
      this.sendNotificationButtonTarget.setAttribute('disabled', true);
    } else {
      this.recipientCheckboxTargets.filter((checkbox) => !checkbox.disabled).forEach((checkbox) => {
        checkbox.checked = true;
      });
      this.notificationRecipientsTarget.value = JSON.stringify(this.recipientCheckboxTargets.filter((checkbox) => !checkbox.disabled).map((checkbox) => parseInt(checkbox.value)));
      this.sendNotificationButtonTarget.removeAttribute('disabled');
    }
  }

  toggleRecipient({ params }) {
    let recipients = JSON.parse(this.notificationRecipientsTarget.value);
    if (recipients.includes(params.recipient)) {
      recipients = recipients.filter((recipient) => recipient !== params.recipient);
    } else {
      recipients.push(params.recipient);
    }
    this.notificationRecipientsTarget.value = JSON.stringify(recipients);

    if (recipients.length > 0) {
      this.sendNotificationButtonTarget.removeAttribute('disabled');
    } else {
      this.sendNotificationButtonTarget.setAttribute('disabled', true);
    }
  }

}
